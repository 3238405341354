import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Study() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
            10/8/2022<br></br><br></br>
        Was feeling pretty mid and then while i was eating dinner nhi asked me to 
        study with her and we studied for like 45 mins maybe 1 hour and the library 
        closed early and she said mchenry is open later(i knew it wasn’t) but said 
        we should go so we walked over there and then we sat outside of there and 
        talked for a long time and then after a while she said she wanted to walk 
        back and i walked back with her and it was fun. I think we are both having 
        fun hanging out so i hope she asks me again<br></br><br></br>
        10/11/2022 10:01PM<br></br><br></br>
        nhi texted me to study with her. That was really fun, we got drinks at the 
        cafe and then had to walk around mchenry a lot to find a place to study and 
        were talking the whole time. We studied for a while and it was fun too. We 
        walked and ate too and it was still just super fun. Then i walked her to 
        the bus stop and because we were talking she didn’t get on one of the loop 
        buses and waited for the next one which was nice.<br></br><br></br>
        10/16/2022 7:06PM<br></br><br></br>
        nhi asked me to go to the library and we had a lot of fun there. Then i got 
        bored of studying so we left and walked to the trailer park and then on a 
        trail for a while back to porter<br></br><br></br>
        10/25/2022 9:26PM (The AM10 hidden notes incident)<br></br><br></br>
        Seeing nhi for the first time in a while was nice and we ate and went 
        to the library together. There was one moment where i was asking to see 
        one of her notes and she said no and i kept saying please and she kept 
        saying no and it was really funny and i got hard yea idk<br></br><br></br>
        <div className='final_text'>
        I like studying with you
        </div>
        </div>
        <div className='bottomright_code_scroll'>
          4w
        </div>
    </div>
  )
}