import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Survivor() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
            2/18/2023<br></br><br></br>
            talked to nhi then wasted some time watching survivor 
            clips lol. I think it  would be a fun thing to watch 
            the upcoming survivor season with nhi if she is interested.<br></br><br></br>
            3/9/2023<br></br><br></br>
            went back and showered and then nhi came over, spent the rest of the 
            day with her watching survivor, finding things for japan, cuddling, and eating<br></br><br></br>
            4/9/2023<br></br><br></br>
            went to nhi’s when i was feeling burnt out, no one was there luckily 
            so we got to have some wonderful alone time, we ate and watched survivor 
            she is so great<br></br><br></br>
        <div className='final_text'>
        I like watching survivor with you
        </div>
        </div>
        <div className='bottomright_code_scroll'>
          1m
        </div>
    </div>
  )
}