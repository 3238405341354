import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Reunite() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
            4/2/2023<br></br><br></br>
            showered then went to walnut with nhi, it was 
            great to see her and it seems like we are on 
            the same page about how we feel about each other, 
            we both missed eachother so much and it was really 
            nice to catch up, we spent the whole day together 
            and i got very horny at times, we spent all day just 
            enjoying eachother and i would say i was in bliss. 
            we took a little walk this morning and then split up, 
            but it really refreshed me for the quarter<br></br><br></br>
        <div className='final_text'>
        This day was quite profound
        </div>
        </div>
        <div className='bottomright_code_absolute'>
          3o
        </div>
    </div>
  )
}