import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Like() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
          10/15/2022 8:15PM<br></br><br></br>
        Then nhi asked to come over to paint her nails and we went downtown. 
        She called me bestie again, i feel like she is making it clear with 
        what she said yesterday and this that she only wants to be friends. So i 
        think ill just try to be ok with that, and honestly it makes me a little 
        more comfortable when i think about it like that. I told her about vanessa 
        today and she said why don’t you redownload instagram to text her back, 
        arent you interested in her? But i just wanted to say no nhi im interested 
        in you<br></br><br></br>
        10/17/2022 10:43PM<br></br><br></br>
        I am more comfortable with nhi now than i have been with anyone in a long time, 
        so im not gonna lose this relationship no matter what. The only thing i can say 
        for now is that if i treat it like a friendship i feel much more comfortable. If 
        she becomes interested so am i. If she doesn’t then i just have a cool asf best 
        friend.<br></br><br></br>
        10/28/2022 7:59PM<br></br><br></br>
        (A long entry about being friends with women, I really
        wanted to tell you I liked you but didn't want to scare you off)<br></br><br></br>
        <div className='final_text'>
          I like ya
        </div>
        </div>
        <div className='bottomright_code_scroll'>
          5l
        </div>
    </div>
  )
}