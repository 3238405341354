import './App.css';
import TimerPage from './timerpage';
import Met from './met';
import Study from './study';
import Like from './like';
import Kiss from './kiss';
import Break from './break';
import Secret from './secret';
import Love from './love';
import Survivor from './survivor';
import Reunite from './reunite';
import Paprika from './paprika';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const timer_page = createBrowserRouter([
  {
    path: "/",
    element: <TimerPage/>,
  },
  {
    path: "met",
    element: <Met />,
  },
  {
    path: "study",
    element: <Study />,
  },
  {
    path: "like",
    element: <Like />,
  },
  {
    path: "kiss",
    element: <Kiss />,
  },
  {
    path: "break",
    element: <Break />,
  },
  {
    path: "secret",
    element: <Secret />,
  },
  {
    path: "love",
    element: <Love />,
  },
  {
    path: "survivor",
    element: <Survivor />,
  },
  {
    path: "reunite",
    element: <Reunite />,
  },
  {
    path: "paprika",
    element: <Paprika />,
  },
]);

function App() {
    return (
      <div className='App'>
        <RouterProvider router={timer_page} />      
      </div>
    );
  }

export default App;
