import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Paprika() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
        4/28/2023 <br></br><br></br>
        i was just really enjoying being around nhi today, maybe it was just 
        because of our new converation thing, i learned paprika by japanese 
        breakfast and sang that to her so that really set the mood right for 
        today, ate together and went through porter meadows and porter caves
         very novel experience <br></br><br></br>
        <div className='final_text'>
        I'm glad you liked my singing
        </div>
        </div>
        <div className='bottomright_code_absolute'>
          7v
        </div>
    </div>
  )
}