import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Kiss() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
          11/15/2022 8:22PM<br></br><br></br>
          Monday i went to 2 of my classes on zoom and that was good because 
          nhi came over. We just messed around a little cuddling and stuff before 
          going to my cs lecture together, then math lecture and then we laid 
          down and kissed at some point. But today we were planning to not hang 
          out but then she came over and we did a little work first while messing 
          around and then kissed quite a bit. it was still really fun. Especially the 
          parts where we were joking around and stuff. She bit my tongue and we 
          kissed with eyes open and stuff.<br></br><br></br>
          11/22/2022 11:10AM<br></br><br></br>
          Nhi came over later and we had fun. We kissed a lot and it was a lot 
          more fun this time, i also kissed her boobs.

        <div className='final_text'>
          I like kissing you
        </div>
        </div>
        <div className='bottomright_code_scroll'>
          6o
        </div>
    </div>
  )
}