import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Break() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
            1/3/2023 10:38PM<br></br><br></br>
            Today with nhi was just so great, we went to the beach 
            and parked in a spot where we could see the water and 
            sand and watched a movie in my trunk. I really like her. 
            She is really great idk what else to say

        <div className='final_text'>
          I really liked this day
        </div>
        </div>
        <div className='bottomright_code_absolute'>
          2e
        </div>
    </div>
  )
}