import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css";
import sound from "./assets/awholenewworld.mp3";

export default function Secret() {
  useEffect(() => {
    new Audio(sound).play();
  }, []);

  return (
    <div>
      <Link to="/" className="topleft_link">
        Back
      </Link>
      <div className="letter-container">
        <div className="letter">
          I think you value going out and doing special things together more
          than me. I realize I should put more effort into going out and doing
          things like going to Davenport with you. But I think this is because I
          feel so safe when I am alone with you. When we're alone I feel like I
          can really let myself out and connect with you most deeply.
          Experiencing things together is great, but I feel closest to you when
          I am just there with you, focusing on you.
          <br />
          <br />
          I admire you a lot. I think it is amazing how you are so dedicated to
          your values. How present and content you are. How positive and
          consistent you are. How aware and honest you are. I love when you tell
          me about it, when you think out loud, I want to learn from you.
          <br /> <br />
          I love joking with you. I feel like a kid, so free, even if you say
          you don't like it. Sorry if it is too much for you sometimes, I just
          have so much fun. I laugh so freely with you, I enjoy being alive so
          much.
          <br />
          <br />
          I decided to do a website and host it because I felt like it
          permanently left those timers up. Idk I thought its kinda like our
          little mark on the world (internet) ig. If you want me to stop hosting it let
          me know. Also if you want to see the videos of me recording my song I
          can give you those too.
          <br /> <br />
          Love ya and happy nasty nineteen,
          <br />
          <br />
          Waylon Puppy Fluffball Q Tip
        </div>
      </div>
    </div>
  );
}
