import { useEffect, useState } from "react";

// you can change it to countdown timer

export default function Timer({start_date}) {
  var startTime = new Date() - new Date(start_date);

  const second = Math.floor((startTime / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((startTime / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hour = Math.floor((startTime / 1000 / 60 / 60) % 24)
    .toString()
    .padStart(2, "0");
    const day = Math.floor((startTime / 1000 / 60 / 60 / 24) % 365)
    .toString()
    .padStart(2, "0");
    const year = Math.floor(startTime / 1000 / 60 / 60 / 24 / 365)
    .toString();

  const [time, setTime] = useState(year + ":" + day + ":" + hour + ":" + minute + ":" + second);


  const showTimer = (ms) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hour = Math.floor((ms / 1000 / 60 / 60) % 24)
    .toString()
    .padStart(2, "0");
    const day = Math.floor((ms / 1000 / 60 / 60 / 24) % 365)
    .toString()
    .padStart(2, "0");
    const year = Math.floor(ms / 1000 / 60 / 60 / 24 / 365)
    .toString();
    setTime(
        year + ":" + day + ":" + hour + ":" + minute + ":" + second
    );
  };

  useEffect(() => {
    var id = setInterval(() => {
      var left = (new Date() - new Date(start_date));
      showTimer(left);
    }, 1);
    return () => clearInterval(id);
  }, []);

  return (
    <div>
       {time}
    </div>
  );
}