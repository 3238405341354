import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Met() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
            9/30/2022 11:11PM<br></br><br></br>
            This one girl tapped me on the shoulder and said she sees me walking around everywhere, 
            and when i asked where she said porter. I don’t really go to porter so i feel like i made 
            a good impression on her. But then after class i casually asked for ‘their’ numbers but hers 
            was the only one i really cared about. Her name is nhi (le)
            <br></br><br></br>
            10/2/2022 6:44PM<br></br><br></br>
            Went to the dining hall pretty sad and feeling like a failure until i saw nhi there, i was really 
            scared and was gonna try to leave before she could see me to not embarrass myself further but then 
            she talked to me and ate with me and she is so cool. Then she came back to my dorm and then we walked 
            all the way to her dorm and yea. She is so cool she is from huntington and she used to play water
             polo and she is the best. She said she has been downtown to thrift and then she said she would 
             take me down there sometime with her. I went to her room and we were together for like an hour until 
             we parted ways but she is so nice i want to be friends with her so bad. Walked home and no joke 
             couldn’t hold in the smiles and now im here writing.
             <br></br><br></br>
             10/5/2022 6:57PM<br></br><br></br>
             Book club was awesome nhi is so coolllll. I just want to keep hanging out with her. And she texted me after, 
             i hope we do something this weekend<br></br><br></br>
            <div className='final_text'>
            I am so glad to have met you
            </div>
        </div>
    </div>
  )
}