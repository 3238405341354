import "./App.css";
import Timer from "./timer";
import React from "react";
import { Link } from "react-router-dom";
import { useKonamiCode } from "./konamiCode";

function TimerPage() {
  const key = useKonamiCode();

  // for some reason the timers are changing when konami code is entered

  if (key) {
    return (
      <>
        <Link to="/secret" className="secret_link">
          Volume&uarr;
        </Link>
        <div className="Title">
        <Timer start_date={"2022-09-30T23:55:00.000Z"} />
        Since we{" "}
        <Link to="/met" className="inplace_link">
          met
        </Link>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2022-10-19T02:00:00.000Z"} />
          Since our first{" "}
          <Link to="/study" className="inplace_link">
            study
          </Link>{" "}
          together
        </div>
        <div class="column">
          <Timer start_date={"2022-10-15T20:00:00.000Z"} />
          Since I was pretty sure I{" "}
          <Link to="/like" className="inplace_link">
            liked
          </Link>{" "}
          you
        </div>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2022-11-16T01:00:00.000Z"} />
          Since our first{" "}
          <Link to="/kiss" className="inplace_link">
            kiss
          </Link>
        </div>
        <div class="column">
          <Timer start_date={"2022-12-18T20:00:00.000Z"} />
          Since our day over{" "}
          <Link to="/break" className="inplace_link">
            winter break
          </Link>{" "}
          in Fountain Valley
        </div>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2023-02-05T08:15:00.000Z"} />
          Since officially{" "}
          <Link to="/love" className="inplace_link">
            loving
          </Link>{" "}
          you
        </div>
        <div class="column">
        <Timer start_date={"2023-02-19T04:00:00.000Z"} />
            Since I suggested <Link to="/survivor" className="inplace_link">
              Survivor
            </Link>{" "}
        </div>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2023-04-02T16:00:00.000Z"} />
          Since <Link to="/reunite" className="inplace_link">
              reuniting
            </Link> after spring break
        </div>
        <div class="column">
          <Timer start_date={"2023-04-28T17:00:00.000Z"} />
          Since singing <Link to="/paprika" className="inplace_link">
              Paprika
            </Link> for you
        </div>
      </div>
      </>
    );
  }
  return (
    <>
      <div className="Title">
        <Timer start_date={"2022-09-30T23:55:00.000Z"} />
        Since we{" "}
        <Link to="/met" className="inplace_link">
          met
        </Link>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2022-10-19T02:00:00.000Z"} />
          Since our first{" "}
          <Link to="/study" className="inplace_link">
            study
          </Link>{" "}
          together
        </div>
        <div class="column">
          <Timer start_date={"2022-10-15T20:00:00.000Z"} />
          Since I was pretty sure I{" "}
          <Link to="/like" className="inplace_link">
            liked
          </Link>{" "}
          you
        </div>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2022-11-16T01:00:00.000Z"} />
          Since our first{" "}
          <Link to="/kiss" className="inplace_link">
            kiss
          </Link>
        </div>
        <div class="column">
          <Timer start_date={"2022-12-18T20:00:00.000Z"} />
          Since our day over{" "}
          <Link to="/break" className="inplace_link">
            winter break
          </Link>{" "}
          in Fountain Valley
        </div>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2023-02-05T08:15:00.000Z"} />
          Since officially{" "}
          <Link to="/love" className="inplace_link">
            loving
          </Link>{" "}
          you
        </div>
        <div class="column">
        <Timer start_date={"2023-02-19T04:00:00.000Z"} />
            Since I suggested <Link to="/survivor" className="inplace_link">
              Survivor
            </Link>{" "}
        </div>
      </div>
      <div class="row">
        <div class="column">
          <Timer start_date={"2023-04-02T16:00:00.000Z"} />
          Since <Link to="/reunite" className="inplace_link">
              reuniting
            </Link> after spring break
        </div>
        <div class="column">
          <Timer start_date={"2023-04-28T17:00:00.000Z"} />
          Since singing <Link to="/paprika" className="inplace_link">
              Paprika
            </Link> for you
        </div>
      </div>
    </>
  );
}

export default TimerPage;
