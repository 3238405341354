import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


export default function Love() {
  return (
    <div>
        <Link to="/" className='topleft_link'>
            Back
        </Link>
        <div className='main_text'>
          2/5/2023 12:15AM<br></br><br></br>
          The more i disconnect the more i connect to others. I 
          felt good today, i care about nhi so much i could tell 
          her i love her. I feel like im making progress<br></br><br></br>


        <div className='final_text'>
          I love you
        </div>
        </div>
        <div className='bottomright_code_absolute'>
          8e
        </div>
    </div>
  )
}